import '../App.css';
function Header(props){
    return (
        <>
            <h1>Joey Paintbrush</h1>
            <h2>
                <a href="mailto:joeypaintbrush@yahoo.com">joeypaintbrush@yahoo.com</a>
            </h2>
        </>
    );
}

export default Header;