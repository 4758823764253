import '../App.css';
import AudioPlayer from "./AudioPlayer";

function AudioList(props){
    return(
        <>
            <div className="App">
                {props.audio.map((value, index) => {
                    return (
                        <>
                            <table>
                                <thead></thead>
                                <tbody>
                                <tr>
                                    <td>
                                        <AudioPlayer value={value} index={index} controls />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </>
                );
                    }
                )}
            </div>
        </>
    );
}

export default AudioList;