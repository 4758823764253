import '../App.css';
import VideoPlayer from "./VideoPlayer";

function VideoList(props){
    return(
        <>
            <div className="App">
                {
                    props.videos.map((value, index) => {
                        return(
                            <>
                                <VideoPlayer key={"video" + index} id={"video" + index} value={value} index={index}/>
                                <br/>
                            </>
                        );
                        }
                    )
                }
            </div>
        </>
    );
}

export default VideoList;