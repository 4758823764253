import '../App.css';

function AudioPlayer(props){
    return(
        <>
            <audio id={"audioid" + props.index} key={"audiokey" + props.index} controls>
                <source src={props.value} type="audio/mpeg" />
            </audio>
        </>
    );
}

export default AudioPlayer;