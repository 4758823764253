import '../App.css';
import ImageView from "./ImageView";

function ImageList(props){
    return(
        <>
            <section id="photos">
                {props.images.map((value, index) => <ImageView id={"imageView"+index} key={"imageView"+index} index={index} value={value} />)}
            </section>
        </>
    );
}

export default ImageList;