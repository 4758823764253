import '../App.css';
import React, {useState, useEffect} from "react";
import ImageList from "./ImageList";
import AudioList from "./AudioList";
import VideoList from "./VideoList";

function ServerFolder(props){
    const [fileListingArray, setFileListingArray] = useState([]);
    console.log("SERVER FOLDER:" + JSON.stringify({location : props.serverFolder}));
    useEffect(() => {
        fetch(props.baseDomainUrl + 'filelisting', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({location : props.serverFolder})
        })
            .then((res => {
                const resJson = res.json();
                const errorStatus = res.status;
                if (errorStatus === 200) {
                    return resJson;
                } else {
                    throw new Error("SERVER FOLDER FILE LISTING/JSON RESPONSE STATUS:" + errorStatus + " VIEW SERVER LOG FOR DETAILS");
                }
            }))
            .then(res => {
                let newFileListingArray = [];
                JSON.parse(res.fileListingArray).map((value) => newFileListingArray.push(props.serverFolder.replace("../", "https://") + value));
                setFileListingArray(newFileListingArray);
            })
            .catch(err => {
                console.error("FETCH COMMAND ERROR:" + err);
            });
    }, [props.baseDomainUrl, props.serverFolder]);
    return (
        <>
            <ImageList id="serverFolderImages" key="serverFolderImages" images={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".gif") ||
                file.toLowerCase().endsWith(".tif") ||
                file.toLowerCase().endsWith(".tiff") ||
                file.toLowerCase().endsWith(".png") ||
                file.toLowerCase().endsWith(".jpeg") ||
                file.toLowerCase().endsWith(".jpg"))}/>
            <AudioList id="serverFolderAudio" key="serverFolderAudio" audio={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".mp3"))}/>
            <VideoList id="serverFolderVideo" key="serverFolderVideo" videos={fileListingArray.filter((file) =>
                file.toLowerCase().endsWith(".mp4") ||
                file.toLowerCase().endsWith(".m4v")
            )}/>
        </>
    );
}

export default ServerFolder;
