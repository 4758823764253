import './App.css';
import React, {useState} from 'react';
import HomePage from "./components/HomePage";

function App() {
    const [baseDomainUrl,] = useState("https://stuffedanimalwar.com:55555/");
    const[serverFolder,] = useState("../seattlerules.com/media/joeypaintbrush/");
    return(
        <HomePage baseDomainUrl={baseDomainUrl} serverFolder={serverFolder}  />
    );
}

export default App;
