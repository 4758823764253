import '../App.css';
import ServerFolder from "./ServerFolder";
import Header from "./Header";

function HomePage(props){
    /*TEXT ONLY INTRODUCTION*/
    const sIntroduction = "Joeseph Masciotra is Seattle's decades long prolific, acrylic on canvas painter.";

    return (
        <>
            <Header />
            <div className="App">
                <p>{sIntroduction}</p><br />
                <p>Joey Paintbrush is also a custom canvas artisan in his own right.<br />
                <a href="https://joeysblankcanvas.com" target="_blank">joeysblankcanvas.com</a>
                </p>
                <ServerFolder baseDomainUrl={props.baseDomainUrl} serverFolder={props.serverFolder}  />
            </div>
        </>
    );
}

export default HomePage;